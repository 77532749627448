/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Muhammad Yulasfi Pahrizal Portfolio",
  description:
    "A passionate individual who always thrives to work on end to end products which develop sustainable and scalable social and technical systems to create impact.",
  og: {
    title: "Muhammad Yulasfi Pahrizal Portfolio",
    type: "website",
    url: "http://ashutoshhathidara.com/",
  },
};

//Home Page
const greeting = {
  title: "Muhammad Yulasfi Pahrizal",
  logo_name: "Pahri",
  nickname: "layman_brother",
  subTitle:
    "A passionate individual who always thrives to work on end to end products which develop sustainable and scalable social and technical systems to create impact.",
  resumeLink: "mailto:muhammadpahrizal790@gmail.com",
  portfolio_repository: "mailto:muhammadpahrizal790@gmail.com",
  githubProfile: "https://github.com/muhammadyulasfipahrizal",
};

const socialMediaLinks = [
  /* Your Social Media Link */
  // github: "https://github.com/ashutosh1919",
  // linkedin: "https://www.linkedin.com/in/ashutosh-hathidara-88710b138/",
  // gmail: "ashutoshhathidara98@gmail.com",
  // gitlab: "https://gitlab.com/ashutoshhathidara98",
  // facebook: "https://www.facebook.com/laymanbrother.19/",
  // twitter: "https://twitter.com/ashutosh_1919",
  // instagram: "https://www.instagram.com/layman_brother/"

  {
    name: "Github",
    link: "https://github.com/muhammadyulasfipahrizal",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/muhammad-yulasfi-pahrizal",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  {
    name: "Instagram",
    link: "https://www.instagram.com/_lazfy",
    fontAwesomeIcon: "fa-instagram", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#E1306C", // Reference https://simpleicons.org/?q=linkedin
  },
  {
    name: "Google",
    link: "mailto:muhammadpahrizal790@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
];

const skills = {
  data: [
    {
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Building responsive website front end using React, Vue, And Asp.Net",
        "⚡ Developing mobile applications using Flutter and React Native",
        "⚡ Creating application backend in Node.Js, Next.Js & Asp.Net Web API",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "Sass",
          fontAwesomeClassname: "simple-icons:sass",
          style: {
            color: "#CC6699",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "devicon-plain:nodejs-wordmark",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "Flutter",
          fontAwesomeClassname: "simple-icons:flutter",
          style: {
            color: "#02569B",
          },
        },
        {
          skillName: "ASP.NET",
          fontAwesomeClassname: "simple-icons:dotnet",
          style: {
            color: "#512BD4",
          },
        },
      ],
    },
    {
      title: "UI/UX Design",
      fileName: "DesignImg",
      skills: [
        "⚡ Designing highly attractive user interface for mobile and web applications",
        "⚡ Customizing logo designs and building logos from scratch",
        "⚡ Creating the flow of application functionalities to optimize user experience",
      ],
      softwareSkills: [
        {
          skillName: "Adobe Photoshop",
          fontAwesomeClassname: "simple-icons:adobephotoshop",
          style: {
            color: "#31A8FF",
          },
        },
        {
          skillName: "Figma",
          fontAwesomeClassname: "simple-icons:figma",
          style: {
            color: "#F24E1E",
          },
        },
        {
          skillName: "Canva",
          fontAwesomeClassname: "simple-icons:canva",
          style: {
            color: "#00C4CC",
          },
        },
      ],
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    {
      siteName: "Kaggle",
      iconifyClassname: "simple-icons:kaggle",
      style: {
        color: "#20BEFF",
      },
      profileLink: "https://www.kaggle.com/laymanbrother",
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Mulia University",
      subtitle: "B.Tech. in Information System",
      logo_path: "Universitas_Mulia.png",
      alt_name: "Mulia University",
      duration: "2016 - 2020",
      descriptions: [
        "⚡ I have studied basic software engineering subjects like Web Development, Algorithms, Data Structure, etc.",
        "⚡ Apart from this, I have done courses on Machine Learning, Data Science, Cloud Computing and Full Stack Development.",
        "⚡ I was the vice chairman in campus student association of Information system",
      ],
      website_link: "https://universitasmulia.ac.id/",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Algorithm And Data Structure",
      subtitle: "- FCC",
      logo_path:
        "CERTIFICATION - ALGORITHM AND DATA STRUCTURE JAVASCRIPT - MUHAMMAD YULASFI PAHRIZAL.JPG",
      certificate_link:
        "https://www.freecodecamp.org/certification/Pahri/javascript-algorithms-and-data-structures",
      alt_name: "ALGORITHM AND DATA STRUCTURE JAVASCRIPT",
      color_code: "#8C151599",
    },
    {
      title: "Javascript Fundamental",
      subtitle: "- Dicoding",
      logo_path:
        "CERTIFICATION - BELAJAR DASAR JAVASCRIPT - MUHAMMAD YULASFI PAHRIZAL.JPG",
      certificate_link: "https://www.dicoding.com/certificates/ERZRGKJ1QPYV",
      alt_name: "Javascript Fundamental",
      color_code: "#00000099",
    },
    {
      title: "Cloud Practitioner Essentials",
      subtitle: "- Dicoding",
      logo_path:
        "CERTIFICATION - DICODING - AWS CLOUD ESSENTIAL - MUHAMMAD YULASFI PAHRIZAL.JPG",
      certificate_link: "https://www.dicoding.com/certificates/07Z6V8032XQR",
      alt_name: "AWS CLOUD ESSENTIAL",
      color_code: "#0C9D5899",
    },
    {
      title: "Front End Development Libraries",
      subtitle: "- FCC",
      logo_path: "CERTIFICATION-FRONTEND-WEB-DEVELOPMENT.JPG",
      certificate_link:
        "https://www.freecodecamp.org/certification/Pahri/front-end-development-libraries",
      alt_name: "Front end development",
      color_code: "#1F70C199",
    },
    {
      title: "Back End Development",
      subtitle: "- Dicoding",
      logo_path: "CERTIFICATION-DICODING-BACKEND-INTERMIDIATE.JPG",
      certificate_link: "https://www.dicoding.com/certificates/2VX3J0993PYQ",
      alt_name: "Back End Development",
      color_code: "#D83B0199",
    },
    {
      title: "Responsive Web Design",
      subtitle: "- FCC",
      logo_path: "CERTIFICATION-RESPOSIVE-WEB-DESIGN-FCC.JPG",
      certificate_link:
        "https://www.freecodecamp.org/certification/Pahri/responsive-web-design",
      alt_name: "Responsive Web Design",
      color_code: "#1F70C199",
    },
    {
      title: "Machine Learning",
      subtitle: "- FCC",
      logo_path: "CERTIFICATION-FCC-MACHINE-LEARNING-WITH-PYTHON.JPG",
      certificate_link:
        "https://www.freecodecamp.org/certification/Pahri/machine-learning-with-python-v7",
      alt_name: "Machine Learning",
      color_code: "#0C9D5899",
    },
    {
      title: "Advanced SQL",
      subtitle: "- Keggle",
      logo_path: "Muhammad Yulasfi Pahrizal - Advanced SQL.png",
      certificate_link:
        "https://www.kaggle.com/learn/certification/mypahrizal/advanced-sql",
      alt_name: "Advanced SQL",
      color_code: "#00000099",
    },
    {
      title: "Data Visualization",
      subtitle: "- FCC",
      logo_path: "CERTIFICATION-FCC-DATA-VISUALIZATION.JPG",
      certificate_link:
        "https://www.freecodecamp.org/certification/Pahri/data-visualization",
      alt_name: "Data Visualization",
      color_code: "#2A73CC",
    },
    {
      title: "Cisco Certified Network Associate",
      subtitle: "- Cisco",
      logo_path: "CERTIFICATION-CISCO-CCNA-ITN.JPG",
      certificate_link:
        "https://www.credly.com/badges/2f8d8237-0e93-46c6-98b8-7088b1326e45/linked_in_profile",
      alt_name: "Cisco Certified Network Associate",
      color_code: "#4285F499",
    },
    {
      title: "Architecting On AWS",
      subtitle: "- Dicoding",
      logo_path: "CERTIFICATION-DICODING-ARCHITECTING-ON-AWS.JPG",
      certificate_link: "https://www.dicoding.com/certificates/07Z6V72JMXQR",
      alt_name: "Architecting On AWS",
      color_code: "#FFBB0099",
    },
    {
      title: "Power BI",
      subtitle: "- Data Camp",
      logo_path: "data_visualization_in_power_bi.JPG",
      certificate_link:
        "https://www.datacamp.com/completed/statement-of-accomplishment/course/871593bdf99508f32e69bf4d76968047d7fe8947",
      alt_name: "Power BI",
      color_code: "#4285F499",
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Volunteership",
  description:
    "I have worked with some well-established companies as a Full Stack Developer, Designer, and Database Administrator. I have also worked on several projects as a freelancer. I love working with cutting-edge technologies, collaborating with creative teams, and tackling complex challenges that drive innovation and enhance user experiences.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      work: true,
      experiences: [
        {
          title: "Full Stack Web Developer",
          company: "Thiess",
          company_url: "https://thiess.com/",
          logo_path: "Thiess_logo.svg.png",
          duration: "Nov 2023 - June 2024",
          location: "Balikpapan, Indonesia",
          description:
            "At This Job, i am working on developing and maintaining web applications to support mining operations. The projects involve building user interfaces, backend services, and ensuring the integration of various data systems to improve operational efficiency and safety. I collaborate with cross-functional teams to deliver scalable and robust solutions.",
          color: "#0879bf",
        },
        {
          title: "Front End Web Developer",
          company: "Trisquare Technology",
          company_url: "https://trisquare.com.my/",
          logo_path: "trisquare.JPG",
          duration: "June 2023 - Nov 2023",
          location: "Remote",
          description:
            "As a Front End Web Developer at Trisquare Technology, I focused on developing a Learning Management System (LMS). My responsibilities included creating responsive and intuitive user interfaces, optimizing web performance, and ensuring cross platform compatibility.",
          color: "#0879bf",
        },
        {
          title: "Intern Developer",
          company: "DILO (Digital Lounge)",
          company_url:
            "https://www.linkedin.com/company/dilo-digital-innovation-lounge-/",
          logo_path: "dilo.png",
          duration: "Apr 2018 - Sep 2018",
          location: "Balikpapan, Indonesia",
          description:
            "I assisted in developing user interface of local goverment applications using HTML, CSS, and JavaScript. This involved creating responsive designs and ensuring compatibility across different browsers and devices. I also assisted in developing and maintaining server-side logic using Node.js",
          color: "#000000",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects makes use of vast variety of latest technology tools. My best experience is to create and deploy web applications  using cloud infrastructure.",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "Publications",
  description: "Some of my published Articles, Blogs and Research.",
  avatar_image_path: "projects_image.svg",
};

const publications = {
  data: [
    {
      id: "neuro-symbolic-sudoku-solver",
      name: "Open Music API",
      createdAt: "2023-07-02T00:00:00Z",
      description: "A Backend Restful Node Js API for Music Recommendation",
      url: "https://arxiv.org/abs/2307.00653",
    },
    {
      id: "mdp-diffusion",
      name: "MDP-Diffusion",
      createdAt: "2023-09-19T00:00:00Z",
      description: "Blog published in Paperspace",
      url: "https://blog.paperspace.com/mdp-diffusion/",
    },
    {
      id: "consistency-models",
      name: "Consistency Models",
      createdAt: "2023-10-12T00:00:00Z",
      description: "Blog published in Paperspace",
      url: "https://blog.paperspace.com/consistency-models/",
    },
  ],
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "photo_2023-09-17_09-45-35.jpg",
    description:
      "I am available on this platform bellow. You can message me, and I will reply within 24 hours. I am happy to help you with all of your software development need.",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "I like to document some of my experiences in professional career journey as well as some technical knowledge sharing.",
    link: "https://blogs.ashutoshhathidara.com/",
    avatar_image_path: "blogs_image.svg",
  },
  addressSection: {
    title: "Address",
    subtitle: "Saratoga Ave, San Jose, CA, USA 95129",
    locality: "San Jose",
    country: "USA",
    region: "California",
    postalCode: "95129",
    streetAddress: "Saratoga Avenue",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://maps.app.goo.gl/NvYZqa34Wye4tpS17",
  },
  phoneSection: {
    title: "",
    subtitle: "",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  publications,
  contactPageData,
};
