import React, { useRef } from "react";
import Header from "../../components/header/Header";
import Greeting from "../../containers/greeting/Greeting";
import Skills from "../../containers/skills/Skills";
import Footer from "../../components/footer/Footer";
import TopButton from "../../components/topButton/TopButton";
import Education from "../education/EducationComponent";
import Experience from "../experience/Experience";
import Contact from "../contact/ContactComponent";
import NavPill from "../../components/NavPill/NavPill";
import Projects from "../projects/Projects";

const Home = (props) => {
  const homeRef = useRef(null);
  const skillsRef = useRef(null);
  const educationRef = useRef(null);
  const experienceRef = useRef(null);
  const projectRef = useRef(null);
  const contactRef = useRef(null);

  const scrollToSection = (section) => {
    if (section === "skills" && skillsRef.current) {
      skillsRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (section === "home" && homeRef.current) {
      homeRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (section === "project" && projectRef.current) {
      projectRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (section === "education" && educationRef.current) {
      educationRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (section === "experience" && experienceRef.current) {
      experienceRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (section === "contact" && contactRef.current) {
      contactRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const isMobile = () => {
    return window.innerWidth < 768;
  };

  return (
    <div>
      {isMobile() ? null : <NavPill scrollToSection={scrollToSection} />}
      <div ref={homeRef}>
        <Header theme={props.theme} />
      </div>
      <Greeting theme={props.theme} />
      <div ref={skillsRef}>
        <Skills theme={props.theme} />
      </div>
      <div ref={educationRef}>
        <Education theme={props.theme} />
      </div>
      <div ref={experienceRef}>
        <Experience theme={props.theme} />
      </div>
      <div ref={projectRef}>
        <Projects theme={props.theme} />
      </div>
      <div ref={contactRef}>
        <Contact theme={props.theme} />
      </div>
      <Footer theme={props.theme} />
      <TopButton theme={props.theme} />
    </div>
  );
};

export default Home;
