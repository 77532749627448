import React, { Component } from "react";
import "./NavPill.css";

const NavPill = ({ scrollToSection }) => {
  return (
    <div className="nav-container">
      <nav className="navbar">
        <div className="nav-background">
          <ul className="nav-list">
            <li>
              <a
                className="nav-item list"
                onClick={() => scrollToSection("home")}
              >
                Home
              </a>
            </li>
            <li>
              <a
                className="nav-item list"
                onClick={() => scrollToSection("skills")}
              >
                Skills
              </a>
            </li>
            <li>
              <a
                className="nav-item list"
                onClick={() => scrollToSection("education")}
              >
                Education
              </a>
            </li>
            <li>
              <a
                className="nav-item list"
                onClick={() => scrollToSection("experience")}
              >
                Experience
              </a>
            </li>
            <li>
              <a
                className="nav-item list"
                onClick={() => scrollToSection("project")}
              >
                Projects
              </a>
            </li>
            <li>
              <a
                className="nav-item list"
                onClick={() => scrollToSection("contact")}
              >
                Contact
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default NavPill;
